<template>
    <!-- 就餐服务-就餐学生 -->
    <component
        v-bind:is="currentTabComponent"
        :detail-data="detailData"
        :list-data="listData"
        @toList="toList"
        @toDetail="toDetail"
        @setPageInitTabs="setPageInitTabs"
    ></component>
</template>
<script>
import RepastStudentList from './RepastStudent/RepastStudentView.vue';
import RepastStudentInfo from './RepastStudent/RepastStudentInfo.vue';

export default {
    name: "RepastStudentScrollWrapper",
    components: {
        RepastStudentList,
        RepastStudentInfo
    },
    data() {
        return {
            // 切换组件
            compList: ['RepastStudentList', 'RepastStudentInfo'],
            idx: 0,
            listData: {},
            detailData: {}
        }
    },
    computed: {
        currentTabComponent() {
            return this.compList[this.idx];
        }
    },
    methods: {
        toList(listData) {
            this.idx = 0
            this.listData = listData
        },
        toDetail(detailData) {
            this.idx = 1
            this.detailData = detailData
        },
        setPageInitTabs() {
            this.$eventDispatch('setGlobalPageType', 'list');
            this.idx = 0;
        }
    }
}
</script>
